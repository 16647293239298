.preloader {
    background: linear-gradient(77.02deg, rgb(61, 0, 64, 0.7) 0%, rgb(64, 0, 67, 0.7) 50.8%, rgb(112, 0, 118, 0.7) 100.56%);
    z-index: 99;
    transition: all 0.3s;
}

.preloader img {
    width: 10%;
    max-width: 50px;
    animation: App-logo-spin infinite 2s linear;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
        opacity: 0;
    }
    to {
        transform: rotate(360deg);
        opacity: 1;
    }
}