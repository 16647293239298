.notification {
    background: #FFFFFF;
    box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.125);
    border-radius: 3px;
    animation: notification-slider 0.3s;
    transform-origin: right;
    min-width: 250px;
}

@keyframes notification-slider {
    0% {
        transform: scaleX(0);
        opacity: 0;
    }
}

.notification h3 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

.notification p {
    font-size: 12px;
    color: #7B7B7B;
}