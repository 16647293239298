@import url('https://fonts.googleapis.com/css2?family=Gilda+Display&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
    width: 3px;
    border-radius: 9px;
}

*::-webkit-scrollbar-track {
    border-radius: 9px;
}

*::-webkit-scrollbar-thumb {
    background: #3d00401a;
}

*::-webkit-scrollbar-thumb:hover {
    background: #3d00403a;
}

html, * {
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.pagination-style .Mui-selected {
    background: #730079 !important;
}

.pagination-style button {
    border-width: 0 !important;
    outline-width: 0 !important;
}